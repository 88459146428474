.ant-tag-checkable-checked {
    background-color: #3c4b64 !important;
}
.ant-tag-checkable {
    margin: 0 !important;
    padding: 0 !important;
}
.ant-tag {
    margin: 0 !important;
}
@media (max-width: 575px) {
    
    .ant-row {
        justify-content: space-between;
    }
    .ant-form-item-control {
        max-width: 50% !important;
    }
    .ant-form-item-control-input-content {
        max-width: 100% !important;
    }
    .ant-form-item-label {
        max-width: 40% !important;
    }
    .try {
        max-width: 100% !important; 
    }
    .login-input {
        width: calc(200% - 24px) !important;
    }
    .payment {
        width: 200% !important;
    }
}
.ant-form-item {
    margin: 0 !important;
}
.ant-space-item {
    width: 100%;
}

.ant-form-item-label > label {
    white-space: pre-wrap !important;
}